import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//采购发布订单列表
export const GetOrderList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/PSOrder/GetOrderList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 录入订单
export const AddOrder = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/PSOrder/AddOrder`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 编辑订单
export const EditOrder = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/PSOrder/EditOrder`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 编辑订单状态
export const EditOrderStatus = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/PSOrder/EditOrderStatus`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//采购订单明细
export const GetOrderDetail = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v3/PSOrder/GetOrderDetail?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}