<template>
    <div class="purchase-publish">
        <!-- 表单 -->
        <div class="facilityBox">
            <el-form class="queryForm" :model="manageForm" :inline="true">
                <el-form-item label="商品名称" prop="goodsName">
                    <el-input class="fromInp" v-model="manageForm.goodsName" placeholder="输入商品名称"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-select v-model="manageForm.status">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="发布中" value="PutOn"></el-option>
                        <el-option label="已下架" value="PutDown"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="10px">
                    <el-button type="primary" plain size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
                    <el-button type="danger" plain size="medium" @click="resetForm()" icon="el-icon-delete">清空
                    </el-button>
                </el-form-item>
            </el-form>
            <div class="top-btn">
                <el-button type="primary" size="mini" @click="openPublishDialog('add')" icon="el-icon-s-promotion">采购发布
                </el-button>
            </div>
            <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
                v-loading="loading">
                <el-table-column type="index" label="序号" width="50" fixed="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="POrderID" label="商品编号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="GoodsName" label="商品名称" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="GoodsPrice" label="商品价格" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span style="color:#FF8B17">{{ scope.row.GoodsPrice | formatMoney }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="GoodsUnitName" label="单位" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="GoodsCounts" label="数量" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="ContactName" label="联系人" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="ReceiveAddress" label="收货地址" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="StatusName" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.Status == 0 ? 'success' : 'info'">{{ scope.row.StatusName }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="Remark" label="备注" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="操作" fixed="right" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-button type="text" @click="openPublishDialog('edit', scope.row)">详情</el-button>
                        <el-button type="text" @click="editPublishStatus('PutOn', scope.row)"
                            v-if="scope.row.Status == 1">发布</el-button>
                        <el-button type="text" @click="editPublishStatus('PutDown', scope.row)"
                            v-if="scope.row.Status == 0" style="color: #999;">下架</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin: 10px 0 0 0">
                <!-- 分页 -->
                <el-pagination class="pagination" background @current-change="handleCurrentChange"
                    :current-page.sync="pagination.page" :page-size="pagination.pagesize"
                    layout="total, prev, pager, next, jumper" :total="pagination.total">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="dialogType == 'add' ? '商品发布' : '商品详情'" :visible.sync="publishDialog" v-if="publishDialog"
            width="700px" :close-on-click-modal="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" :inline="true">
                <el-form-item label="联系人" prop="ContactName">
                    <el-input v-model="ruleForm.ContactName" clearable placeholder="请输入联系人" class="form-con"></el-input>
                </el-form-item>
                <el-form-item label="商品名称" prop="GoodsName">
                    <el-input v-model="ruleForm.GoodsName" clearable placeholder="请输入商品名称" class="form-con"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="ContactPhone">
                    <el-input v-model="ruleForm.ContactPhone" clearable placeholder="请输入联系电话" :maxlength="11"
                        class="form-con">
                    </el-input>
                </el-form-item>
                <el-form-item label="商品价格" prop="GoodsPrice">
                    <el-input v-model="ruleForm.GoodsPrice" clearable placeholder="请输入商品价格" class="form-con"></el-input>
                </el-form-item>
                <el-form-item label="收货地址" prop="ReceiveAddress">
                    <el-input v-model="ruleForm.ReceiveAddress" clearable placeholder="请输入收货地址" class="form-con">
                    </el-input>
                </el-form-item>
                <el-form-item label="商品单位" prop="GoodsUnit">
                    <el-select v-model="ruleForm.GoodsUnit" clearable placeholder="请选择商品单位" class="form-con">
                        <el-option v-for="item in goodsUnitList" :key="item.Code" :label="item.Name" :value="item.Code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="收货时间" prop="ReceiveDate">
                    <el-date-picker v-model="ruleForm.ReceiveDate" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                        placeholder="请选择收货时间" class="form-con">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="采购数量" prop="GoodsCounts">
                    <el-input v-model="ruleForm.GoodsCounts" clearable placeholder="请输入采购数量" class="form-con"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="Remark">
                    <el-input v-model="ruleForm.Remark" type="textarea" clearable placeholder="请输入备注" class="form-con">
                        <span>{{ ruleForm.goodsUnit }}</span>
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="info" plain @click="publishDialog = false">取消</el-button>
                <el-button type="danger" plain @click="editPublishStatus('del', currentItem)"
                    v-if="dialogType == 'edit'">删除
                </el-button>
                <el-button type="primary" @click="savePublish">{{ dialogType == 'add' ? '发布' : '修改发布' }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getDataDict } from "@/api/common/common.js";
import { GetOrderList, AddOrder, EditOrder, EditOrderStatus, GetOrderDetail } from "@/api/purchaseManage/purchasePublish";
import verify from "@/utils/verify";
import { formatMoney } from "../../../../utils/filter";
import { mapGetters } from "vuex";
export default {
    data() {
        var checkPrice = (rule, value, callback) => {
            if (!verify._integer(value)) {
                callback(new Error('请输入正确的格式'));
            } else {
                callback();
            }
        };
        var checkPhone = (rule, value, callback) => {
            if (!verify._phone(value)) {
                callback(new Error('请输入正确的格式'));
            } else {
                callback();
            }
        };
        return {
            // 筛选条件数据
            manageForm: {
                goodsName: "",
                status: ""
            },
            //分页控件相关参数
            pagination: {
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
                total: 0, //总条数
            },
            // 表单数据
            tableData: [],
            loading: true,
            goodsUnitList: [],
            publishDialog: false,
            dialogType: '',
            ruleForm: {
                GoodsName: '',
                GoodsPrice: null,
                GoodsUnit: '',
                GoodsCounts: null,
                ContactName: '',
                ContactPhone: '',
                ReceiveAddress: '',
                ReceiveDate: '',
                Remark: ''
            },
            rules: {
                GoodsName: [
                    { required: true, message: '请输入商品名称', trigger: 'blur' }
                ],
                GoodsPrice: [
                    { required: true, message: '请输入商品价格', trigger: 'blur' },
                    { validator: checkPrice, trigger: 'blur' }
                ],
                GoodsUnit: [
                    { required: true, message: '请选择商品单位', trigger: 'change' }
                ],
                GoodsCounts: [
                    { required: true, message: '请输入采购数量', trigger: 'blur' },
                    { validator: checkPrice, trigger: 'blur' }
                ],
                ContactName: [
                    { required: true, message: '请输入联系人', trigger: 'blur' }
                ],
                ContactPhone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                    { validator: checkPhone, trigger: 'blur' }
                ],
                ReceiveAddress: [
                    { required: true, message: '请输入收货地址', trigger: 'blur' }
                ],
                ReceiveDate: [
                    { required: true, message: '请选择收货时间', trigger: 'change' }
                ]
            },
            currentItem: {}
        };
    },
    methods: {
        // 清空搜索
        resetForm() {
            this.pagination.page = 1;
            this.manageForm = {
                goodsName: "",
                status: ""
            };
            this.tableData = []
            this.getOrderList();
        },
        // 搜索
        search() {
            this.pagination.page = 1;
            this.tableData = []
            this.getOrderList();
        },
        //分页控件页码change事件回调
        handleCurrentChange(e) {
            this.pagination.page = e;
            // 获取列表
            this.getOrderList();
        },
        // 获取列表
        getOrderList() {
            this.loading = true
            let params = {
                pageSize: this.pagination.pagesize,
                pageIndex: this.pagination.page,
                ...this.manageForm,
                queryEnvironment: 'Pc'
            };
            GetOrderList(params).then((res) => {
                this.tableData = res.data.DataList;
                this.pagination.total = Number(res.data.TotalCount);
            }).finally(() => {
                this.loading = false;
            })
        },
        // 打开弹窗
        openPublishDialog(type, row) {
            this.dialogType = type
            if (type == 'add') {
                this.ruleForm = {
                    GoodsUnit: '04',
                    ReceiveAddress: this.getEnterpriserAddress
                }
                this.publishDialog = true
                this.currentItem = {}
            } else {
                this.currentItem = row
                GetOrderDetail({ pOrderID: row.POrderID }).then(res => {
                    this.ruleForm = res.data || {}
                    this.publishDialog = true
                })
            }
        },
        // 提交发布
        savePublish() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    const loading = this.$loading({
                        lock: true,
                        text: '请求中...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    let ajaxFun = this.dialogType == 'add' ? AddOrder : EditOrder
                    ajaxFun(this.ruleForm).then(res => {
                        this.$message.success(this.dialogType == 'add' ? '发布成功' : '修改成功')
                        this.publishDialog = false
                        this.getOrderList()
                    }).finally(() => {
                        loading.close()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑状态
        editPublishStatus(status, row) {
            this.$confirm('确定要进行此项操作吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                EditOrderStatus({
                    pOrderID: row.POrderID,
                    status
                }).then(res => {
                    this.$message.success("操作成功")
                    this.getOrderList()
                    this.publishDialog = false
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        }
    },
    created() {
        this.getOrderList()
        // 获取物品单位
        getDataDict({ type: 28 }).then((res) => {
            this.goodsUnitList = res.patterSetInfo;
        });
    },
    computed: {
        ...mapGetters(["getEnterpriserAddress"]),
    },
}
</script>
<style lang="scss">
.purchase-publish {
    .form-con {
        width: 240px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        .el-textarea__inner,
        .el-input__inner {
            text-align: center;
            vertical-align: middle;
        }

        .el-textarea__inner {
            margin-top: 40px;
            padding: 20px;
            box-sizing: border-box;
        }
    }
}
</style>